// i18next-extract-mark-ns-start products-ignite-pfe

import { IGNITE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductIgnitePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							IGNITE predicts fuel consumption and tailpipe emissions of a vehicle system over a specified operational cycle,
							as well as vehicle performance characteristics such as acceleration, gradeability and top speed.
							These capabilities enable users to explore the impact that various technologies and controls, such as hybridisation,
							have on vehicle performance and fuel economy and produced emissions.
						</p>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="Performance, fuel economy and emissions aftertreatment" />
						</p>
					)}

					<Trans i18nKey="content_2">
						<h2>Key features</h2>
						<ul>
							<li>
								Longitudinal vehicle model including grade and pitch
							</li>
							<li>
								Independent throttle and brake control for vehicle performance manoeuvres
							</li>
							<li>
								Experimental and predictive fuel map modelling
							</li>
							<li>
								Direct coupling with WAVE and WAVE-RT for detailed engine modelling
							</li>
							<li>
								Co-simulation with MATLAB Simulink® for controls development
							</li>
							<li>
								Tyre slip models
							</li>
							<li>
								Simulation of varying terrain and elevation
							</li>
							<li>
								Transmission efficiencies at all operating points can be easily exported from SABR to quickly access drive cycle energy consumption
							</li>
							<li>
								Shift and torque converter lock-up strategy
							</li>
							<li>
								Vehicle launch control models
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{IGNITE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductIgnitePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-ignite-pfe", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/ignite/ignite-hero_1152x654.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/ignite/ignite-performance.png" }) {
		...imageStandard
	}
}
`;
